import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const DoDontComponent = makeShortcode("DoDontComponent");
const Do = makeShortcode("Do");
const CaptionTitle = makeShortcode("CaptionTitle");
const ImageCaption = makeShortcode("ImageCaption");
const Dont = makeShortcode("Dont");
const AdaptiveImage = makeShortcode("AdaptiveImage");
const Link = makeShortcode("Link");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton link="/content" mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Grammar and mechanics</Heading>
      <Text mdxType="Text">
  Consistent language helps users navigate apps confidently. Like visuals, words
  that blend seamlessly into the interface strengthen our identity and build
  trust.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Basics</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use American spellings: license, organize, synchronize, center, dependent,
    favorite.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Choose simple tenses. For most cases, prefer <i>was</i> over <i>has been</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <img src="https://github.com/user-attachments/assets/b7c0f5b9-15c3-4ab9-9328-41b521b72929" alt="Simple and succinct success message title." />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Simplify your message titles to “Task created,” “Payment failed,” etc.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
    <img src="https://github.com/user-attachments/assets/ddd03126-dd0f-4c01-927a-30eda3b7ac6d" alt="Overly complex success message title." />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Avoid indirect present perfect tense, and skip redundant{" "}
      <i>successfully</i>.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Use sentence case</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    It's easier to maintain, read, and understand.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Apply to all interface elements, including buttons and headings, unless it's
    an exception.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Capitalize the first letter of a sentence, UI label, proper nouns, days,
    months, etc.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't capitalize the names of features, such as <i>column view</i> or{" "}
    <i>post function.</i>
  </DoDontChecklistItem>
  <DoDontComponent vertical mdxType="DoDontComponent">
    <Dont variant={`blockquote`} mdxType="Dont">
      A feature is anything that does something within an app. While some
      features might seem special, their significance is subjective and often
      perceived differently by users.
    </Dont>
  </DoDontComponent>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't capitalize <i>project</i>, <i>scope</i>, <i>sprint</i>, or{" "}
    <i>iteration</i>, as those are common nouns.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't capitalize <i>a</i> in <i>the app</i> when referring to your product.
    We're not braggy.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
     <AdaptiveImage darkSrc="https://github.com/user-attachments/assets/fb8bb4a0-d19c-4f9c-812c-6d4541f6d466" lightSrc="https://github.com/user-attachments/assets/754bdeb7-6ab2-47b4-a4d0-e3841973332a" alt="Example of a consistently used sentence case." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Consistent use of sentence case makes information easier to follow and
      understand.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
  <AdaptiveImage darkSrc="https://github.com/user-attachments/assets/464dc7fe-c909-4879-a6a2-47127c76d6b5" lightSrc="https://github.com/user-attachments/assets/fffb6e83-ef3e-49aa-ac5f-502f1a373727" alt="Example of combining sentence and title case." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Inconsistent capitalization makes apps seem sloppy and unprofessional.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Exceptions to sentence case</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Respect the original spelling of our products, packages, and services.
  </DoDontChecklistItem>
   <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Respect the original spelling of terms coming from other ecosystems.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use all caps to write “Save PDF” or “Export CSV.” Add lowercase <i>s</i> for
    plurals, like <i>JPGs</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use all caps for other necessary acronyms.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use all caps for text in distinct UI elements such as lozenges or dropdown
    group labels.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/747ad4e8-bab4-47fc-a3c7-d42b4cb46c24" darkSrc="https://github.com/user-attachments/assets/43a5c670-0892-432f-b331-7651b3840630" alt="Examples of lozenges and dropdown group labels written in all caps." mdxType="AdaptiveImage" />
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Terminal punctuation</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim for short tooltips and inline messages without periods.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use periods when there's more than one sentence or other punctuation marks.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use periods in sentences followed by links (“New tokens available.{" "}
    <Link href={`https://www.fuegokit.design/tokens/`} mdxType="Link">Go to Fuegokit</Link>”).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use terminal punctuation to end those links.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use terminal punctuation for titles and headings.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
    <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/3c3f5506-9b6a-4a88-8f2b-ada7f5d1e6ba" darkSrc="https://github.com/user-attachments/assets/2f039971-23d6-420b-a287-3e68e2920ca7" alt="Short tooltip that doesn't require terminal punctuation." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Omitting periods helps short text elements flow smoothly and look
      informative.
    </ImageCaption>
  </Do>
  <Do variant={`border`} mdxType="Do">
   <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/3f1f14e3-6ebe-491f-98c9-878ada87f5a8" darkSrc="https://github.com/user-attachments/assets/ee57ac5b-d142-41fd-b349-0bbb24304451" alt="Tooltip consisting of 2 sentences, requiring terminal punctuation." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Consistency requires terminal punctuation for multiple and complex
      sentences.
    </ImageCaption>
  </Do>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Other punctuation</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Improve clarity with an Oxford comma to join independent clauses or list
    multiple items.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use quotation marks only to quote someone. To distinguish text, use{" "}
    <i>italics</i> or <b>bold</b>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Reserve ellipses to indicate an action in progress, like <i>Loading…</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use colons to introduce a bulleted list in body text but not after headings.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use colons at the end of labels above dropdown lists.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid semicolons; use commas or split your sentence into two.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't overdo it with exclamation marks! Too many, or too often, are likely
    to be annoying!
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid parentheses except for definitions, as they create ambiguity and
    disrupt scanning.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent vertical mdxType="DoDontComponent">
  <Do mdxType="Do">
    <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/f2fd1606-9e55-4760-8aef-3e110d0154f3" darkSrc="https://github.com/user-attachments/assets/368a6cb1-f228-4774-8c92-fc27bb3c16bf" alt="4 examples of simple and efficient content writing without parentheses." mdxType="AdaptiveImage" />
    <ImageCaption mdxType="ImageCaption">
      Make it easy to grasp important details and what action to take.
    </ImageCaption>
  </Do>
  <Dont mdxType="Dont">
    <AdaptiveImage lightSrc="https://github.com/user-attachments/assets/516d8f86-6f5a-4a0e-b952-a81826a5f592" darkSrc="https://github.com/user-attachments/assets/17963e21-5b41-40bd-8ad8-9c27267d25f5" alt="4 examples of unnecessary parentheses use across the interface." mdxType="AdaptiveImage" />
    <ImageCaption mdxType="ImageCaption">
      Don't break the flow or make people decide if singular or plural is more
      relevant.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Dashes and hyphens</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use hyphens (-) to join words (well-known, high-quality, etc.) or indicate a
    range (0-10).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Choose unhyphenated spelling if both are correct (email, post function,
    subtask, etc.).
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use em dashes (—) to break up a sentence—and don't add spaces.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use em dashes sparingly. Often it's better to start a new sentence.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't use ampersand (&) in body text unless it's a part of the product name.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Limit ampersands in other UI elements. They are less accessible than{" "}
    <i>and</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Contractions</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use simple contractions (you'll, it's, there's, etc.) to sound natural.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use negative contractions (can't, don't, won't, etc.) most of the time.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Consider spelling out negatives when overlooking <i>not</i> could have
    critical implications.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Spell out noun + verb contractions. <i>Browser is</i> reads better than{" "}
    <i>browser's</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid using complex phrases such as <i>should have</i> and their
    contractions like <i>should’ve</i>.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Abbreviations and acronyms</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Write <i>for example</i> or <i>such as</i> for more conversational copy.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>e.g.,</i> (followed by a comma) only when space is minimal, such as
    in placeholders.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>etc.</i> sparingly.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    If an acronym is necessary, define it first using parentheses.
  </DoDontChecklistItem>
  <DoDontComponent vertical mdxType="DoDontComponent">
    <Do variant={`blockquote`} mdxType="Do">
      The Universal Plugin Manager (UPM) provides a powerful and user-friendly
      interface. Through the UPM, you can browse, try, and buy apps.
    </Do>
  </DoDontComponent>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't explain common acronyms that are not app-specific, such as PDF, HTML,
    or FAQ.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Numbers and numerals</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use numerals to improve scanning for specific data.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use numerals for dates and times, units, steps, instructions, and formulas.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    In general contexts, use numerals for <i>2</i> and above.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    In general contexts, write <i>zero</i> and <i>one</i> in words to avoid
    confusion with <i>o</i> and <i>l</i>.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use commas for 1,000 and above.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent mdxType="DoDontComponent">
  <Do variant={`border`} mdxType="Do">
     <AdaptiveImage darkSrc="https://github.com/user-attachments/assets/605ed5bc-ecbf-4247-8ccf-d5f7a515a51c" lightSrc="https://github.com/user-attachments/assets/29e21977-9c09-4a56-9fb9-16d567e5986e" alt="An example of large numbers written correctly in US format." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Do</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      Breaking up large numbers with a comma ensures quick and easy scanning.
    </ImageCaption>
  </Do>
  <Dont variant={`border`} mdxType="Dont">
  <AdaptiveImage darkSrc="https://github.com/user-attachments/assets/406576f0-3cdf-433e-b002-22b9e0edccd7" lightSrc="https://github.com/user-attachments/assets/1f84b021-21b3-4572-adce-bec57d923482" alt="An example of large numbers written incorrectly in US format." mdxType="AdaptiveImage" />
    <CaptionTitle mdxType="CaptionTitle">Don't</CaptionTitle>
    <ImageCaption mdxType="ImageCaption">
      US format reserves dots for decimals, while spaces can break formatting.
    </ImageCaption>
  </Dont>
      </DoDontComponent>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">UI references</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Aim to write about values and capabilities, not UI elements.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When a UI element reference is necessary, match the case and punctuation
    exactly.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <b>bold</b> for buttons, checkboxes, fields, labels, options, and
    toggles.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use <i>italics</i> for page and tab names, headings, and dialog titles.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Don't add terms like <i>button</i> or <i>option</i>. Having a clear context
    makes them redundant.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
    Avoid saying <i>above</i>, <i>below</i>, <i>here</i>, etc., as those are
    relative and inaccessible.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <DoDontComponent mdxType="DoDontComponent">
  <Do variant={`blockquote`} mdxType="Do">
    Clear, direct instructions help people quickly understand what to do:<br></br>
    “Select <b>Hide parent tasks</b> to see only tasks matching your criteria.”
  </Do>
  <Dont variant={`blockquote`} mdxType="Dont">
    Redundant terms complicate things:<br></br>
    “Select the <b>Hide parent tasks</b> option below to see only tasks matching
    your criteria.”
  </Dont>
      </DoDontComponent>
      <hr />
      <Text mdxType="Text">Share your feedback on the #design-systems channel in Slack.</Text>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      